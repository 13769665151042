import React, { useEffect, useRef, useState } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { TogglePoiFormControl, ToggleRouteFormControl, ToggleHillshadeFormControl } from './MapComponentButtons';

const MapComponent = () => {
  const mapContainer = useRef(null);
  const mapRef = useRef(null);
  const [formPoiData, setFormPoiData] = useState({
    latitude: 47.27574,
    longitude: 11.39085,
    distance: 500,
    limit: 100,
  });

  const [formRouteData, setFormRouteData] = useState({
    distance: 500,
    limit: 100,
    slatitude: null,
    slongitude: null,
    elatitude: null,
    elongitude: null
  });

  const [showPoiForm, setShowPoiForm] = useState(false);
  const [showRouteForm, setShowRouteForm] = useState(false);
  const [routeCoordinates, setRouteCoordinates] = useState([]);

  useEffect(() => {
    const map = new maplibregl.Map({
      container: mapContainer.current,
      center: [formPoiData.longitude, formPoiData.latitude],
      zoom: 11,
      pitch: 60,
      maxPitch: 85,
      maxZoom: 18,
      style: 'https://tiles.versatiles.org/assets/styles/colorful.json',
    });

    mapRef.current = map;

    map.on('load', () => {
      map.on('click', (e) => {
        if (showRouteForm) {
          if (!formRouteData.slatitude && !formRouteData.slongitude) {
            setFormRouteData({
              ...formRouteData,
              slatitude: parseFloat(e.lngLat.lat.toFixed(6)),
              slongitude: parseFloat(e.lngLat.lng.toFixed(6))
            });
            setRouteCoordinates([[e.lngLat.lng, e.lngLat.lat]]);
          } else {
            setFormRouteData({
              ...formRouteData,
              elatitude: parseFloat(e.lngLat.lat.toFixed(6)),
              elongitude: parseFloat(e.lngLat.lng.toFixed(6))
            });
            setRouteCoordinates(prev => [...prev, [e.lngLat.lng, e.lngLat.lat]]);
          }
        } else {
          const newFormPoiData = {
            ...formPoiData,
            latitude: parseFloat(e.lngLat.lat.toFixed(6)),
            longitude: parseFloat(e.lngLat.lng.toFixed(6))
          };
          setFormPoiData(newFormPoiData);
          fetchPOIs(newFormPoiData);
        }
      });

      map.addSource('terrain', {
        type: 'raster-dem',
        url: 'https://demotiles.maplibre.org/terrain-tiles/tiles.json',
        tileSize: 256
      });

      map.addSource('hillshade', {
        type: 'raster-dem',
        url: 'https://demotiles.maplibre.org/terrain-tiles/tiles.json'
      });

      map.addLayer({
        id: 'hillshade',
        source: 'hillshade',
        type: 'hillshade',
        layout: { visibility: 'visible' },
        paint: { 'hillshade-shadow-color': '#473B24' }
      });

      map.setTerrain({ source: 'terrain', exaggeration: 1.5 });

      map.addControl(new maplibregl.FullscreenControl(), 'top-right');
      map.addControl(
        new maplibregl.NavigationControl({
          visualizePitch: true,
          visualizeRoll: true,
          showZoom: true,
          showCompass: true
        }), 'top-right'
      );

      map.addControl(
        new maplibregl.TerrainControl({
          source: 'terrain',
          exaggeration: 1
        }), 'top-right'
      );

      map.addControl(new ToggleHillshadeFormControl(), 'top-right');
      map.addControl(new TogglePoiFormControl(setShowPoiForm, setShowRouteForm, fetchPOIs, formPoiData), 'top-right');
      map.addControl(new ToggleRouteFormControl(setShowPoiForm, setShowRouteForm, clearPOIs), 'top-right');
    });

    map.on('error', (e) => {
      console.error('Map error:', e.error);
    });

    return () => map.remove();
  }, []);

  useEffect(() => {
    if (routeCoordinates.length === 2) {
      if (mapRef.current.getSource('route')) {
        mapRef.current.getSource('route').setData({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: routeCoordinates
          }
        });
      } else {
        mapRef.current.addSource('route', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: routeCoordinates
            }
          }
        });

        mapRef.current.addLayer({
          id: 'route-layer',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': '#888',
            'line-width': 8
          }
        });
      }
    }
  }, [routeCoordinates]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormPoiData({
      ...formPoiData,
      [name]: name === 'latitude' || name === 'longitude' ? parseFloat(value) : value
    });
  };

  const clearPOIs = () => {
    if (mapRef.current.getSource('pois')) {
      mapRef.current.getSource('pois').setData({
        "type": "FeatureCollection",
        "features": []
      });
    }
  }

  const fetchPOIs = (data) => {
    const { latitude, longitude, distance, limit } = data;
    fetch(`https://overpass.armando.software/poi?lat=${latitude}&lon=${longitude}&distance=${distance}&limit=${limit}`, {
      headers: {
        'Accept-Encoding': 'gzip',
      }
    })
      .then(response => response.json())
      .then(async data => {
        const poiData = {
          type: 'FeatureCollection',
          features: data.features
        };

        if (mapRef.current.getSource('pois')) {
          mapRef.current.getSource('pois').setData(poiData);
        } else {
          mapRef.current.addSource('pois', {
            type: 'geojson',
            data: poiData
          });

          const categoryToMakiIcon = {
            "Rest": "highway-rest-area",
            "Bike": "bicycle-share",
            "Scenic": "viewpoint",
            "Safety": "hospital",
            "Recreation": "natural",
            "Routes": "racetrack-cycling",
            "Drinking Water": "drinking-water",
            "Bench": "bench",
            "Bicycle Shop": "bicycle",
            "Cafe": "cafe",
            "Restaurant": "restaurant",
            "Bars": "bar",
            "Museums": "museum",
            "Buildings": "building",
            "Others": "other"
          };

          const categories = new Set(data.features.map(f => f.properties.poi_category));
          console.log('Categories:', categories);

          const otherImage = await mapRef.current.loadImage('/pois/other.png')
          mapRef.current.addImage('other', otherImage.data);

          for (const category of categories) {
            const icon = categoryToMakiIcon[category] || 'other';
            const iconUrl = `/pois/${icon}.png`;

            console.log(`Loading icon for category: ${category} from ${iconUrl}`);

            if (!mapRef.current.hasImage(icon)) {
              console.log(`Icon not found for ${icon}, loading...`);
              try {
                const image = await mapRef.current.loadImage(iconUrl)
                mapRef.current.addImage(icon, image.data);
              } catch (error) {
                console.error(`Error loading icon for ${icon}:`, error);
                mapRef.current.addImage(icon, otherImage.data);
              }
            }
          }

          mapRef.current.addLayer({
            id: 'poi-layer',
            type: 'symbol',
            source: 'pois',
            layout: {
              'icon-image': [
                'case',
                ['has', 'poi_category'],
                ['get', ['get', 'poi_category'], ['literal', categoryToMakiIcon]],
                'other'
              ],
              'icon-size': 0.2,
              'icon-allow-overlap': true,
              'icon-anchor': 'bottom'
            }
          });

          let popup = new maplibregl.Popup();

          mapRef.current.on('mouseenter', 'poi-layer', (e) => {
            if (e.features.length > 0) {
              mapRef.current.getCanvas().style.cursor = 'pointer';

              const coordinates = e.features[0].geometry.coordinates.slice();
              const properties = e.features[0].properties;

              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }

              var title = properties.amenity ? `<strong>${properties.amenity.toUpperCase()}</stron><br>` : `<strong>${properties.poi_category}</stron><br>`;
              title = title.replaceAll('_', ' ');

              // iterate over all properties and add them to the popup
              var all = "";
              for (const key in properties) {
                if (!key.startsWith('poi_category_symbol')) {
                  all += `<strong>${key}:</strong> ${properties[key]}<br>`;
                }
              }

              const name = properties.name ? `<strong>Name:</strong> ${properties.name}<br>` : '';
              const shop = properties.shop ? `<strong>Shop:</strong> ${properties.shop}<br>` : '';
              const openingHours = properties.opening_hours ? `<strong>Opening Hours:</strong> ${properties.opening_hours}<br>` : '';
              const website = properties.website ? `<strong>Website:</strong> <a href="${properties.website}" target="_blank" rel="noopener noreferrer" style="word-wrap: break-word;">${properties.website}</a><br>` : '';

              // console.log('POI Properties:', properties);  
              popup.setLngLat(coordinates)
                .setHTML(`${title}${name}${shop}${openingHours}${website}${all}`)
                .addTo(mapRef.current);
            }
          });

          mapRef.current.on('mouseleave', 'poi-layer', () => {
            mapRef.current.getCanvas().style.cursor = '';
            popup.remove();
          });
        }

        console.log('POIs updated successfully.');
      })
      .catch(error => console.error('Error fetching POIs:', error));
  };

  return (
    <div style={{ position: 'relative' }}>
      <div ref={mapContainer} style={{ width: '100%', height: '400px' }} />
      {showPoiForm && (
        <div style={{ position: 'absolute', top: '10px', left: '10px', backgroundColor: 'white', padding: '10px', borderRadius: '5px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)' }}>
          <form style={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: '2px' }}>
            <label style={{ fontSize: '12px' }}>
              Lat:
            </label>
            <input type="number" name="latitude" value={formPoiData.latitude} onChange={handleChange} style={{ fontSize: '12px', width: '85px' }} />
            <label style={{ fontSize: '12px' }}>
              Lon:
            </label>
            <input type="number" name="longitude" value={formPoiData.longitude} onChange={handleChange} style={{ fontSize: '12px', width: '85px' }} />
            <label style={{ fontSize: '12px' }}>
              Dist (m):
            </label>
            <input type="number" name="distance" value={formPoiData.distance} onChange={handleChange} style={{ fontSize: '12px', width: '85px' }} />
            <label style={{ fontSize: '12px' }}>
              Limit:
            </label>
            <input type="number" name="limit" value={formPoiData.limit} onChange={handleChange} style={{ fontSize: '12px', width: '85px' }} />
          </form>
        </div>
      )}

      {showRouteForm && (
        <div style={{ position: 'absolute', top: '10px', left: '10px', backgroundColor: 'white', padding: '10px', borderRadius: '5px', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)' }}>
          <form style={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: '2px' }}>
            <label style={{ fontSize: '12px' }}>
              SLat:
            </label>
            <input type="number" name="slatitude" value={formRouteData.slatitude || ''} onChange={handleChange} style={{ fontSize: '12px', width: '85px' }} />
            <label style={{ fontSize: '12px' }}>
              SLon:
            </label>
            <input type="number" name="slongitude" value={formRouteData.slongitude || ''} onChange={handleChange} style={{ fontSize: '12px', width: '85px' }} />
            <label style={{ fontSize: '12px' }}>
              ELat:
            </label>
            <input type="number" name="elatitude" value={formRouteData.elatitude || ''} onChange={handleChange} style={{ fontSize: '12px', width: '85px' }} />
            <label style={{ fontSize: '12px' }}>
              ELon:
            </label>
            <input type="number" name="elongitude" value={formRouteData.elongitude || ''} onChange={handleChange} style={{ fontSize: '12px', width: '85px' }} />
          </form>
        </div>
      )}
    </div>
  );
};

export default MapComponent;

