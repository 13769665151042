import React from 'react';
import maplibregl from 'maplibre-gl';

export class TogglePoiFormControl {
  constructor(setShowPoiForm, setShowRouteForm, fetchPOIs, formPoiData) {
    this.setShowPoiForm = setShowPoiForm;
    this.setShowRouteForm = setShowRouteForm;
    this.fetchPOIs = fetchPOIs;
    this.formPoiData = formPoiData;
  }

  onAdd(map) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = 'maplibregl-ctrl maplibregl-ctrl-group';
    const button = document.createElement('button');
    button.textContent = 'POIs';
    button.innerHTML = '<img src="/icons/landmark-JP.svg" alt="Route" style="width: 20px; height: 20px;" />';
    button.onclick = () => {
      this.setShowRouteForm(false);
      this.setShowPoiForm(prev => !prev);
      if (!this.setShowPoiForm) {
        this.fetchPOIs(this.formPoiData);
      }
    };
    this._container.appendChild(button);
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

export class ToggleRouteFormControl {
  constructor(setShowPoiForm, setShowRouteForm, clearPOIs) {
    this.setShowPoiForm = setShowPoiForm;
    this.setShowRouteForm = setShowRouteForm;
    this.clearPOIs = clearPOIs;
  }

  onAdd(map) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = 'maplibregl-ctrl maplibregl-ctrl-group';
    const button = document.createElement('button');
    button.innerHTML = '<img src="/icons/racetrack-cycling.svg" alt="Route" style="width: 20px; height: 20px;" />';
    button.onclick = () => {
      this.setShowPoiForm(false);
      this.setShowRouteForm(prev => !prev);
      this.clearPOIs();
    };
    this._container.appendChild(button);
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

export class ToggleHillshadeFormControl {
  onAdd(map) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = 'maplibregl-ctrl maplibregl-ctrl-group';
    const button = document.createElement('button');
    button.innerHTML = '<img src="/icons/mountain.svg" alt="Route" style="width: 20px; height: 20px; filter: invert(48%) sepia(100%) saturate(5000%) hue-rotate(180deg) brightness(100%) contrast(100%);" />';
    button.onclick = () => {
      const visibility = map.getLayoutProperty('hillshade', 'visibility') === 'visible' ? 'none' : 'visible';
      map.setLayoutProperty('hillshade', 'visibility', visibility);
      button.querySelector('img').style.filter = visibility === 'visible' 
        ? 'invert(48%) sepia(100%) saturate(5000%) hue-rotate(180deg) brightness(100%) contrast(100%)' 
        : 'grayscale(100%)';
    };
    this._container.appendChild(button);
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}
